.button {
    padding: 0.5rem 0.5rem;
    color: #ffc300;
    text-transform: uppercase;
    border-radius: 6px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.medium {
    font-size: 0.75rem;
}

.small {
    font-size: 0.5rem;
}

.plain {
    color: white;
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
}

.plain:hover,
.plain:active {
    background-color: white;
    color: var(--accent-color);
}

.outlined {
    background-color: transparent;
    color: var(--accent-color);
    border: 1px solid var(--accent-color);
}

.outlined:hover,
.outlined:active {
    background-color: var(--accent-color);
    color: white;
}