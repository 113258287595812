.HomePageContainer {
    z-index: 0;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.LeftSide {
    background-color: var(--background-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 43vw;
    height: 100vh;
    z-index: -1;
}

.CollinCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    height: fit-content;
    width: 300px;
    background-color: var(--background-color2);
    box-shadow: -5px 5px 18px 3px rgba(0, 0, 0, 0.274);
    margin-right: 15px;
}

.CollinCard h1 {
    font-family: 'Roboto Slab', serif;
    margin: 10px;
}

.CollinCard h2 {
    margin: 30px;
    font-family: 'Roboto Slab', serif;
}

.photo {
    padding: 20px;
    width: 50%;
    height: width;
    border-radius: 90%;
}

.ColorLine {
    margin-top: 10px;
    width: 50px;
    height: 4px;
    background-color: var(--accent-color);
}

.ExternalLinks {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.ExternalLinks i {
    margin: 10px;
}

.ExternalLinks i:hover {
    color: var(--accent-color);
}

.IntroCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 300px;
    height: 457px;
}

.IntroCard h1 {
    font-size: 60pt;
    margin: 0;
    margin-bottom: 30px;
}

.ButtonContainer {
    display: flex;
    width: 80%;
    justify-content: space-around;
    margin-bottom: 25px;
}

.IntroCard span {
    margin-bottom: 25px;
}

@media only screen and (max-width: 768px) {
    .HomePageContainer {
        flex-direction: column;
        background-color: var(--background-color);
    }

    .CollinCard {
        margin-top: 15px;
    }

    .LeftSide {
        width: 100vw;
        height: 100vh;
    }
}