.projectBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
}

.projectDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: -5px 5px 18px 3px rgba(0, 0, 0, 0.274);
    margin-bottom: 10px;
}

.description {
    padding: 15px;
    margin: 15px;
}

.buttonContainer {
    margin: 15px;
}

.buttonContainer button {
    margin: 15px;
}

.image {
    width: 400px;
    height: 325px;
}