.FooterContainer {
    z-index: 1;
    display: flex;
    background-color: white;
    height: 10vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.footerBox {
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: center;
}

.footerBox div {
    font-weight: bolder;
    font-size: larger;
}

.footerBox span {
    font-size: large;
}

.footerBox i {
    margin: 5px;
}

.footerBox i:hover {
    color: var(--accent-color);
}