.HeaderContainer {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    z-index: 1;
    background-color: white;
}

.LogoSide {
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.Name {
    font-weight: bolder;
    font-size: x-large;
}

.Slash {
    font-size: large;
    font-weight: bolder;
    margin-left: 10px;
    padding-top: 5px;
}


.Title {
    margin-left: 10px;
    padding-top: 5px;
}

.NavBar {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-right: 50px;
}

.NavBar a {
    margin: 10px;
    text-decoration: none;
    font-size: 18pt;
    font-weight: bold;
}

.NavBar a:visited {
    text-decoration: none;
    color: black;
}

.NavBar a:hover {
    color: var(--accent-color);
}

.NavIcon {
    display: none;
}

.navBarPopUp {
    z-index: 80;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 38px;
    left: 0;
    height: 100vh;
    width: 100%;
}

.navBarPopUp a {
    text-decoration: none;
    font-size: 3rem;
    margin: 15px;
}

.navBarPopUp a:visited {
    text-decoration: none;
    color: black;
}

.navBarPopUp a:hover {
    color: var(--accent-color);
}

@media only screen and (max-width: 768px) {
    .NavBar {
        display: none;
    }

    .NavIcon {
        display: block;
        padding: 10px;
        margin-right: 50px;
    }
}